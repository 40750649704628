import dayjs from "dayjs"
import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
  useRef
} from "react"
import ReactGA from "react-ga"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import packageJson from "../package.json"
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "./static/css/bootstrap.min.css"
import "./static/css/bootstrap.patch.css"
import "./App.css"
import AppRoute from "./components/AppRoute"
import { Toast } from "primereact/toast"
import { unregister } from "./serviceWorkerRegistration"
import { AppProvider } from "./contexts/app-context"
import ApiService from "./xhr_libs"
import UpdateNotification from "./components/UpdateNotification"
import {
  CLIENT_VERSION_KEY,
  NEXT_VERSION_CHECK,
  NEW_VERSION_AVAILABLE,
  TARGET_CLIENT_VERSION_KEY
} from "./storage"
import AdminRoutes from "./pages/Admin/AdminRoutes"
import LoadingComponent from "./common/LoadingComponent"
import { useToastStore } from "./stores/toastStore"

const utc = require("dayjs/plugin/utc")
dayjs.extend(utc)

const VERSION_CHECK_INTERVAL = 5 // 5 minutes

let ApplicationEnvironment = "development"
if (process.env.REACT_APP_API_FQDN) {
  ApplicationEnvironment =
    process.env.REACT_APP_API_FQDN.includes("staging") &&
    !process.env.REACT_APP_API_FQDN.includes("localhost")
      ? "staging"
      : "production"
}

if (["staging", "production"].includes(ApplicationEnvironment)) {
  Sentry.init({
    dsn: "https://fb73d2a2d412449ea2dfe84c32ef1fbf@o432500.ingest.sentry.io/5385280",
    release: `onsite-web@${packageJson.version}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1,
    environment: ApplicationEnvironment
  })
}

if (ApplicationEnvironment === "production") {
  ReactGA.initialize("G-97FBE7VG0P")
}

const About = lazy(() => import("./pages/About"))
const FieldInspectionReportShow = lazy(
  () => import("./pages/FieldInspectionReportShow")
)
const FieldInspectionReport = lazy(
  () => import("./pages/FieldInspectionReport")
)
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"))
const Login = lazy(() => import("./pages/Login"))
const Logout = lazy(() => import("./pages/Logout"))
const MultiInspection = lazy(() => import("./pages/MultiInspection"))
const Profile = lazy(() => import("./pages/Profile"))
const Pricing = lazy(() => import("./pages/Pricing"))
const SetPassword = lazy(() => import("./pages/SetPassword"))

const checkForNewVersion = (callback) => {
  ApiService.get("/_health").then((resp) => {
    const nextCheckInterval = dayjs()
      .utc()
      .add(VERSION_CHECK_INTERVAL, "minute")
      .toString()
    if (resp?.data?.version) {
      localStorage.setItem(NEXT_VERSION_CHECK, nextCheckInterval)
      localStorage.setItem(
        TARGET_CLIENT_VERSION_KEY,
        resp.data.version
      )

      if (resp.data.version !== packageJson.version) {
        console.debug(
          `New client version available: ${packageJson.version}. Current Version: ${resp.data.version}`
        )
        localStorage.setItem(NEW_VERSION_AVAILABLE, true)
        callback(true)
      } else {
        localStorage.setItem(NEW_VERSION_AVAILABLE, false)
        callback(false)
      }
    }
  })
}

const ensureLatestVersion = (callback) => {
  const cachedDate = localStorage.getItem(NEXT_VERSION_CHECK)
  const targetVersion = localStorage.getItem(
    TARGET_CLIENT_VERSION_KEY
  )
  if (!cachedDate || !targetVersion)
    return checkForNewVersion(callback)

  const nowEpoch = dayjs().utc().valueOf()
  const checkTimeEpoch = dayjs(cachedDate).valueOf()
  if (nowEpoch > checkTimeEpoch) return checkForNewVersion(callback)

  if (packageJson.version === targetVersion) {
    localStorage.removeItem(NEW_VERSION_AVAILABLE)
    return callback(false)
  }
}

const App = () => {
  const [showNewVersionNotification, setShowNewVersionNotification] =
    useState(false)

  const toastRef = useRef(null)
  const setToastRef = useToastStore((state) => state.setToastRef)

  useEffect(() => {
    localStorage.setItem(CLIENT_VERSION_KEY, packageJson.version)

    if (ApplicationEnvironment === "production") {
      ReactGA.pageview(
        window.location.pathname + window.location.search
      )
    }

    const showNewVersion = localStorage.getItem(NEW_VERSION_AVAILABLE)
    if (showNewVersion) setShowNewVersionNotification(true)

    ensureLatestVersion((show) => setShowNewVersionNotification(show))
    setToastRef(toastRef)
  }, [])

  const handleReloadOrDismiss = (reloadOrDismiss) => {
    setShowNewVersionNotification(false)

    if (!reloadOrDismiss) {
      localStorage.removeItem(NEW_VERSION_AVAILABLE)
      return
    }

    caches
      .keys()
      .then((keys) =>
        Promise.all(keys.map((key) => caches.delete(key)))
          .then(() => unregister())
          .catch((err) =>
            console.error("Something went wrong: ", err)
          )
      )
      .finally(() => window.location.reload(true))
  }

  return (
    <div className="App">
      {showNewVersionNotification && (
        <UpdateNotification onClick={handleReloadOrDismiss} />
      )}
      <AppProvider>
        <Toast ref={toastRef} position="bottom-right" />
        <Router>
          <Suspense fallback={<LoadingComponent />}>
            <Routes>
              {/* Unauthenticated Routes */}
              <Route
                path="/login"
                element={
                  <AppRoute component={Login} navigation={false} />
                }
              />
              <Route
                path="/logout"
                element={
                  <AppRoute component={Logout} navigation={false} />
                }
              />
              <Route
                path="/forgotPassword"
                element={
                  <AppRoute
                    component={ForgotPassword}
                    navigation={false}
                  />
                }
              />
              <Route
                path="/setPassword/:id/:token"
                element={
                  <AppRoute
                    component={SetPassword}
                    navigation={false}
                  />
                }
              />
              <Route
                path="/pricing"
                element={
                  <AppRoute component={Pricing} navigation={false} />
                }
              />
              <Route
                path="/"
                element={
                  <Navigate replace to="/field_inspection_report" />
                }
              />

              {/* Authenticated Routes */}
              <Route
                path="/about"
                element={<AppRoute component={About} authenticated />}
              />
              <Route
                path="/field_inspection_report/all"
                element={
                  <AppRoute
                    component={FieldInspectionReportShow}
                    authenticated
                  />
                }
              />
              <Route
                path="/field_inspection_report"
                element={
                  <AppRoute
                    component={FieldInspectionReport}
                    authenticated
                  />
                }
              />
              <Route
                path="/profile"
                element={
                  <AppRoute component={Profile} authenticated />
                }
              />
              <Route
                path="/inspection"
                element={
                  <AppRoute
                    component={MultiInspection}
                    authenticated
                  />
                }
              />

              {/* Admin Routes */}
              <Route path="/admin/*" element={<AdminRoutes />} />
            </Routes>
          </Suspense>
        </Router>
      </AppProvider>
    </div>
  )
}

export default App
