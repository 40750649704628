import { create } from "zustand"

export const useToastStore = create((set) => ({
  toastRef: null,
  setToastRef: (ref) => set({ toastRef: ref }),
  showToast: ({ title, variant, body }) => {
    set((state) => {
      if (state.toastRef) {
        state.toastRef.current.show({
          severity: variant === "danger" ? "error" : variant,
          summary: title,
          detail: body,
          life: 3000
        })
      }
      return state
    })
  }
}))
