import React, { useState, useEffect, useCallback } from "react"
import {
  extractClaims,
  getAuthToken,
  removeAuthToken,
  setAuthToken,
  validToken
} from "../auth"
import ApiService from "../xhr_libs"
import { getDefaultOrg, setDefaultOrg } from "../org"
import { OFFLINE_MODE_KEY } from "../storage"

export const PROFILE_KEY = "profile"
const OFFLINE_MODE = JSON.parse(
  localStorage.getItem(OFFLINE_MODE_KEY)
)

const initialState = Object.freeze({
  id: null,
  email: "",
  role: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  user_organizations: [],
  isOnline: false,
  selectedOrg: -1
})

function getInitialState() {
  const isOnline = window.navigator.onLine
  const claims = extractClaims(getAuthToken())
  const selectedOrg = getDefaultOrg()

  if (Object.keys(claims).length > 0) {
    return {
      ...initialState,
      ...claims,
      isOnline: isOnline && !OFFLINE_MODE,
      selectedOrg
    }
  } else {
    return {
      ...initialState,
      isOnline: isOnline && !OFFLINE_MODE,
      selectedOrg
    }
  }
}

export const AppContext = React.createContext(initialState)

export function AppProvider({ children }) {
  const [state, setState] = useState(() => getInitialState())

  const setRole = useCallback(
    (userOrganizations, newSelectedOrg = null) => {
      setState((prev) => {
        const selectedOrg = newSelectedOrg ?? prev.selectedOrg
        const role = userOrganizations.find(
          (org) => org.organization_id === selectedOrg
        )?.role

        return role ? { ...prev, role } : prev
      })
    },
    []
  )

  const fetchProfile = useCallback(() => {
    if (!validToken()) return

    ApiService.get("/profile")
      .then((res) => {
        setUser(res.data)
        setRole(res.data.user_organizations)
      })
      .catch((e) => {
        const cachedUserData = JSON.parse(
          localStorage.getItem(PROFILE_KEY)
        )
        if (cachedUserData) {
          setUser(cachedUserData)
        }

        if (e && e.code === "ERR_NETWORK") {
          setOffline()
        }
      })
  }, [setRole])

  const changeOrganization = useCallback(
    (orgId) => {
      setDefaultOrg(orgId)
      setState((prev) => ({ ...prev, selectedOrg: orgId }))
      setRole(state.user_organizations, orgId)
    },
    [setRole, state.user_organizations]
  )

  const setToken = useCallback((newToken) => {
    setAuthToken(newToken)
    const claims = extractClaims(getAuthToken())

    setState((prev) => ({ ...prev, ...claims }))
  }, [])

  const setUser = useCallback((userData, callback) => {
    localStorage.setItem(PROFILE_KEY, JSON.stringify(userData))
    setState((prev) => ({ ...prev, ...userData }))
    if (typeof callback === "function") {
      callback()
    }
  }, [])

  const logoutUser = useCallback(() => {
    removeAuthToken()
    setState(initialState)
  }, [])

  const setOnline = useCallback(() => {
    console.info("Back online")
    setState((prev) => ({ ...prev, isOnline: true }))
    localStorage.setItem(OFFLINE_MODE_KEY, false)
  }, [])

  const setOffline = useCallback(() => {
    console.warn("Going offline")
    setState((prev) => ({ ...prev, isOnline: false }))
    localStorage.setItem(OFFLINE_MODE_KEY, true)
  }, [])

  useEffect(() => {
    window.addEventListener("online", setOnline)
    window.addEventListener("offline", setOffline)

    fetchProfile()

    return () => {
      window.removeEventListener("online", setOnline)
      window.removeEventListener("offline", setOffline)
    }
  }, [fetchProfile, setOnline, setOffline])

  const contextValue = {
    ...state,
    setToken,
    setUser,
    setOnline,
    setOffline,
    logoutUser,
    changeOrganization
  }

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  )
}

export const AppConsumer = AppContext.Consumer
