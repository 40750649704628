import React from "react"
import ApiService from "../../xhr_libs"
import { withRouter } from "../../hoc/withRouter"
import LoadingComponent from "../../common/LoadingComponent"
import { TabPanel, TabView } from "primereact/tabview"
import { Chart } from "primereact/chart"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Loader } from "react-bootstrap-typeahead"

const CHART_OPTS_AUTHOR = {
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true,
      beginAtZero: true
    }
  }
}

const CHART_OPTS_REPORT = {}

const CHART_OPTS_ORG = {
  scales: {
    y: {
      beginAtZero: true
    }
  }
}

const DATE_COLS = [
  "1 week",
  "30 days",
  "60 days",
  "90 days",
  "6 months",
  "1 year",
  "All Time"
]
const REPORT_COLS = [
  "Air Seal",
  "Rough",
  "Insulation",
  "HVAC",
  "Blower Door",
  "Final",
  "Commissioning",
  "Total"
]

const REPORT_TITLE_LOOKUP = [
  ["count_air_seal", "Air Seal"],
  ["count_rough", "Rough"],
  ["count_insulation", "Insulation"],
  ["count_duct", "Duct"],
  ["count_blower_door", "Blower Door"],
  ["count_final", "Final"],
  ["count_commissioning", "Commissioning"]
]

const STATE_LOOKUP = [
  ["orgData", "org"],
  ["authorData", "author"],
  ["reportData", "report"]
]

class Trends extends React.Component {
  state = {
    authorData: {
      all_time: 0,
      monthly: []
    },
    authorDataLoaded: false,
    isLoading: true,
    orgData: {
      all_time: 0,
      monthly: []
    },
    orgDataLoaded: false,
    reportData: {
      monthly: []
    },
    reporDataLoaded: false,
    siteData: [],
    userData: [],
    ToastMessage: null
  }

  componentDidMount() {
    this.fetchTrends()
    this.fetchChartData()
  }

  fetchChartData = () => {
    STATE_LOOKUP.forEach((value) => {
      const [key, dataKey] = value
      ApiService.get(`/organizations/admin/trends/${dataKey}`)
        .then((responseData) => {
          const newChart = {}
          newChart[key] = responseData.data
          this.setState(newChart)
        })
        .catch((err) => {
          // Toast
          throw err
        })
        .finally(() => {
          const newLoading = {}
          newLoading[`${key}Loaded`] = true
          this.setState(newLoading)
        })
    })
  }

  fetchTrends = () => {
    this.setState({ isLoading: true })
    ApiService.get(`/organizations/admin/trends`)
      .then((res) => {
        this.setState({
          siteData: res.data.site,
          userData: res.data.user
        })
      })
      .catch((err) => {
        // Toast
        throw err
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  toYYYMM(inputDate) {
    const [year, month] = inputDate.split("-")
    return `${year}-${month.padStart(2, "0")}` // Convert to 'YYYY-MM'
  }

  renderAuthorByMonthChart(chartData, isLoaded) {
    if (!isLoaded) return <Loader isLoading={!isLoaded} />

    const allDates = [
      ...new Set(
        chartData.map((entry) => {
          return this.toYYYMM(entry.date)
        })
      )
    ]

    allDates.sort()

    const allAuthors = [
      ...new Set(
        chartData.map((entry) => {
          return entry.name
        })
      )
    ]

    const dateMap = allDates.reduce((acc, d) => {
      acc[d] = {}
      return acc
    }, {})

    chartData.forEach((entry) => {
      const { name: author, date } = entry
      const entryDate = this.toYYYMM(date)

      if (!dateMap[entryDate][author]) {
        dateMap[entryDate][author] = {}
      }

      REPORT_TITLE_LOOKUP.forEach(([key, title]) => {
        if (!dateMap[entryDate][author][title]) {
          dateMap[entryDate][author][title] = 0
        }
        dateMap[entryDate][author][title] += entry[key]
      })
    })

    const datasets = allAuthors.map((author) => {
      return {
        label: author,
        data: allDates.map((date) => {
          const counts = dateMap[date][author] || {}
          return Object.values(counts).reduce((a, b) => a + b, 0)
        }),
        borderWidth: 1
      }
    })

    const CHART_OPTS_AUTHOR_WITH_TOOLTIP = {
      ...CHART_OPTS_AUTHOR,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              const { datasetIndex, dataIndex } = context
              const author =
                context.chart.data.datasets[datasetIndex].label
              const date = context.chart.data.labels[dataIndex]
              const counts = dateMap[date][author]

              let tooltipText = `${author}: ${context.raw}`

              REPORT_TITLE_LOOKUP.forEach(([key, title]) => {
                const count = counts[title] || 0
                tooltipText += `\n ${title}: ${count}`
              })

              return tooltipText
            }
          }
        }
      }
    }

    return (
      <div className="card mb-4">
        <h5 className="card-header">Contribution History</h5>
        <Chart
          type="bar"
          data={{
            labels: allDates,
            datasets: datasets
          }}
          options={CHART_OPTS_AUTHOR_WITH_TOOLTIP}
        />
      </div>
    )
  }

  renderAuthorChart(chartData, isLoaded) {
    if (!isLoaded) return <Loader isLoading={!isLoaded} />

    const allDates = [
      ...new Set(
        chartData.map((entry) => {
          return this.toYYYMM(entry.date)
        })
      )
    ]

    allDates.sort()

    // Aggregate data by author, ensuring each count_ value is correctly mapped.
    const aggregatedData = chartData.reduce((acc, entry) => {
      const { name: author } = entry

      if (!acc[author]) {
        acc[author] = REPORT_TITLE_LOOKUP.reduce(
          (acc2, [_, label]) => {
            acc2[label] = {
              data: Array(allDates.length).fill(0),
              dates: [...allDates]
            }
            return acc2
          },
          {}
        )
      }

      const zeroPadDate = this.toYYYMM(entry.date)
      REPORT_TITLE_LOOKUP.forEach(([countKey, label]) => {
        const dateIndex =
          acc[author][label].dates.indexOf(zeroPadDate)
        acc[author][label].data[dateIndex] = entry[countKey]
      })

      return acc
    }, {})

    const authorCharts = Object.keys(aggregatedData).map(
      (author, index) => {
        const datasets = REPORT_TITLE_LOOKUP.map(([_, label]) => {
          const { data } = aggregatedData[author][label]
          return {
            label,
            data,
            borderWidth: 1
          }
        })

        return (
          <div key={index} className="card mb-4">
            <h5 className="card-header">{author}</h5>
            <Chart
              type="bar"
              data={{
                labels: allDates,
                datasets
              }}
              options={CHART_OPTS_AUTHOR}
            />
          </div>
        )
      }
    )

    return <>{authorCharts}</>
  }

  renderOrgChart(chartData, isLoaded) {
    if (!isLoaded) return <Loader isLoading={!isLoaded} />
    const transformedChartData = chartData.reduce(
      (acc, orgChartData) => {
        acc.labels.push(this.toYYYMM(orgChartData.date))
        acc.datasets[0].data.push(orgChartData.count)
        return acc
      },
      {
        labels: [],
        datasets: [
          {
            label: "Reports Uploaded",
            data: [],
            borderWidth: 3,
            tension: 0
          }
        ]
      }
    )
    return (
      <div className="card mb-4">
        <h5 className="card-header">Total Reports per month</h5>
        <Chart
          type="line"
          data={transformedChartData}
          options={CHART_OPTS_ORG}
        />
      </div>
    )
  }

  renderReportChart(chartData, isLoaded) {
    if (!isLoaded) return <Loader isLoading={!isLoaded} />
    const transformedChartData = chartData.reduce(
      (acc, reportChartData) => {
        acc.labels.push(this.toYYYMM(reportChartData.date))
        REPORT_TITLE_LOOKUP.forEach(([key, title]) => {
          const existingDataset = acc.datasets.find(
            (dataset) => dataset.label === title
          )
          existingDataset.data.push(reportChartData[key])
        })
        return acc
      },
      {
        labels: [],
        datasets: REPORT_TITLE_LOOKUP.map(([key, title]) => {
          return {
            label: title,
            data: [],
            borderWidth: 2
          }
        })
      }
    )

    return (
      <div className="card mb-4">
        <h5 className="card-header">Inspection Type Breakdown</h5>
        <Chart
          type="bar"
          data={transformedChartData}
          options={CHART_OPTS_REPORT}
        />
      </div>
    )
  }

  renderTable(data, title) {
    const columnOrders = {
      Organization: [...DATE_COLS],
      Report: [...REPORT_COLS],
      Site: ["Site", ...DATE_COLS],
      User: ["Name", ...DATE_COLS]
    }
    const keys = columnOrders[title]
    return (
      <div className={`d-flex flex-column gap-2 my-3`}>
        <DataTable
          value={data}
          responsiveLayout="scroll"
          showGridlines
          stripedRows
        >
          {keys.map((key, index) => (
            <Column key={index} field={key} header={key} />
          ))}
        </DataTable>
      </div>
    )
  }

  renderReportTotals(data, isLoaded) {
    if (!isLoaded) return <Loader isLoading={!isLoaded} />
    return REPORT_TITLE_LOOKUP.map((reportKeyTitle) => {
      const [key, title] = reportKeyTitle
      const allTimeCount = data.all_time[key]
      return (
        <div key={key}>
          <h5>{title}</h5>
          {this.renderTotals(data, key, isLoaded, allTimeCount)}
        </div>
      )
    })
  }

  renderTotals(data, countKey, isLoaded, allTime) {
    if (!isLoaded) return <Loader isLoading={!isLoaded} />
    const reversedData = data.monthly.slice().reverse()
    const aggregates = data.monthly.reduce(
      (acc, countObj, idx) => {
        const count = countObj[countKey]
        if (idx < 1) acc.oneMonth += count
        if (idx < 3) acc.threeMonth += count
        if (idx < 6) acc.sixMonth += count
        if (idx < 12) acc.twelveMonth += count
        return acc
      },
      { oneMonth: 0, threeMonth: 0, sixMonth: 0, twelveMonth: 0 }
    )

    // Calculate previous periods
    const previousMonth = data.monthly
      .slice(1, 2)
      .reduce((sum, countObj) => sum + countObj[countKey], 0)
    const previousThreeMonth = data.monthly
      .slice(3, 6)
      .reduce((sum, countObj) => sum + countObj[countKey], 0)
    const previousSixMonth = data.monthly
      .slice(6, 12)
      .reduce((sum, countObj) => sum + countObj[countKey], 0)

    // Calculate percentage differences
    const oneMonthDiff =
      previousThreeMonth === 0
        ? 0
        : ((aggregates.oneMonth - previousMonth) / previousMonth) *
          100
    const threeMonthDiff =
      previousThreeMonth === 0
        ? 0
        : ((aggregates.threeMonth - previousThreeMonth) /
            previousThreeMonth) *
          100
    const sixMonthDiff =
      previousSixMonth === 0
        ? 0
        : ((aggregates.sixMonth - previousSixMonth) /
            previousSixMonth) *
          100

    const getPercentageClass = (value) =>
      value >= 0 ? "text-success" : "text-danger"
    return (
      <div className="d-flex flex-row justify-content-around flex-wrap">
        <div
          className="card text-center mb-3"
          style={{ width: "10rem" }}
        >
          <div className="card-body">
            <h5 className="card-title">All Time</h5>
            <p className="card-text display-6">{allTime}</p>
          </div>
        </div>

        <div
          className="card text-center mb-3"
          style={{ width: "10rem" }}
        >
          <div className="card-body">
            <h5 className="card-title">Last 12 Months</h5>
            <p className="card-text display-6">
              {aggregates.twelveMonth}
            </p>
          </div>
        </div>

        <div
          className="card text-center mb-3"
          style={{ width: "10rem" }}
        >
          <div className="card-body">
            <h5 className="card-title">Last 6 Months</h5>
            <p className="card-text display-6">
              {aggregates.sixMonth}
            </p>
            <p
              className={`card-text fw-bold ${getPercentageClass(sixMonthDiff)}`}
            >
              {sixMonthDiff >= 0
                ? `+${sixMonthDiff.toFixed(2)}`
                : sixMonthDiff.toFixed(2)}
              % ({previousSixMonth})
            </p>
          </div>
        </div>

        <div
          className="card text-center mb-3"
          style={{ width: "10rem" }}
        >
          <div className="card-body">
            <h5 className="card-title">Last 3 Months</h5>
            <p className="card-text display-6">
              {aggregates.threeMonth}
            </p>
            <p
              className={`card-text fw-bold ${getPercentageClass(threeMonthDiff)}`}
            >
              {threeMonthDiff >= 0
                ? `+${threeMonthDiff.toFixed(2)}`
                : threeMonthDiff.toFixed(2)}
              % ({previousThreeMonth})
            </p>
          </div>
        </div>

        <div
          className="card text-center mb-3"
          style={{ width: "10rem" }}
        >
          <div className="card-body">
            <h5 className="card-title">This Month</h5>
            <p className="card-text display-6">
              {aggregates.oneMonth}
            </p>
            <p
              className={`card-text fw-bold ${getPercentageClass(oneMonthDiff)}`}
            >
              {oneMonthDiff >= 0
                ? `+${oneMonthDiff.toFixed(2)}`
                : oneMonthDiff.toFixed(2)}
              % ({previousMonth})
            </p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      authorData,
      authorDataLoaded,
      isLoading,
      orgData,
      orgDataLoaded,
      reportData,
      reportDataLoaded,
      siteData,
      ToastMessage
    } = this.state
    if (isLoading) return <LoadingComponent />
    return (
      <React.Fragment>
        {ToastMessage}
        <TabView className="mb-3">
          <TabPanel header="Organization">
            <div>
              {this.renderOrgChart(orgData.monthly, orgDataLoaded)}
              <hr />
              {this.renderTotals(
                orgData,
                "count",
                orgDataLoaded,
                orgData.all_time
              )}
            </div>
          </TabPanel>
          <TabPanel header="Report">
            <div>
              {this.renderReportChart(
                reportData.monthly,
                reportDataLoaded
              )}
              <hr />
              {this.renderReportTotals(reportData, reportDataLoaded)}
            </div>
          </TabPanel>
          <TabPanel header="User">
            <div>
              {this.renderAuthorByMonthChart(
                authorData.monthly,
                authorDataLoaded
              )}
              <hr />
              {this.renderAuthorChart(
                authorData.monthly,
                authorDataLoaded
              )}
            </div>
          </TabPanel>
          <TabPanel header="Site">
            {this.renderTable(siteData, "Site")}
          </TabPanel>
        </TabView>
      </React.Fragment>
    )
  }
}

Trends.propTypes = {}

export default withRouter(Trends)
